import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="dropdown-menu"
export default class extends Controller {
  connect() {
    const x = this.element
    this.element.addEventListener("turbo:click", e => {
      console.log("turbo visit dropdown", e, this.element)
      this.element.removeAttribute("open");
    })
    this.element.addEventListener("turbo:submit-start", e => {
      console.log("turbo submit dropdown", e, this.element)
      this.element.removeAttribute("open");
    })
  }
}
